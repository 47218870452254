import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import React from "react"
import useStations from "../utils/hooks/useStations"

const HelpPage = () => {
  const { station } = useStations()
  return (
    <Layout station={station}>
      <Seo title="Get Help" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <h1>Get Help</h1>

            <p className="intro">
              Our goal is to provide a positive and productive NEWA experience
              for you.
            </p>

            <p>
              Contact the <a href="https://newa.zendesk.com">NEWA Help Desk</a>{" "}
              with your questions and problems for any of the following reasons.
              Simply send a message to{" "}
              <a href="mailto:support@newa.zendesk.com">
                support@newa.zendesk.com
              </a>
              .
            </p>

            <hr></hr>

            <h2>General Questions</h2>
            <ul>
              <li>
                <Link to="/about-weather-stations">About Weather Stations</Link>
              </li>
            </ul>

            <h2>Joining NEWA</h2>
            <ul>
              <li>As a station owner in NEWA member states</li>
              <li>As an individual grower in non-member states</li>
              <li>Becoming a NEWA member state</li>
              <li>
                Consult NEWA’s{" "}
                <Link to="/become-partner">Become a Partner</Link> page
              </li>
            </ul>

            <h2>Hardware onboarding and support</h2>
            <ul>
              <li>
                Adding a new weather station; consult NEWA’s{" "}
                <Link to="/placement-guide">Placement Guide</Link>
              </li>
              <li>
                Replacing an existing weather station; consult{" "}
                <Link to="/buy-a-weather-station">Buy a Weather Station</Link>
              </li>
              <li>
                Troubleshooting hardware issues; consult NEWA’s{" "}
                <Link to="/maintenance-guide">Maintenance Guide</Link>
              </li>
              <li>
                Troubleshooting data connection issues; consult NEWA’s{" "}
                <Link to="/maintenance-guide">Maintenance Guide</Link>
              </li>
            </ul>

            <h2>Using NEWA tools</h2>
            <ul>
              <li>Getting started and additional information</li>
              <li>Crop-specific resources and tools</li>
              <li>Weather-based tools</li>
              <li>Reporting an error or bug</li>
            </ul>

            <h2>Weather data access</h2>
            <ul>
              <li>Historical data retrieval</li>
              <li>Degree day information</li>
              <li>Reporting an error or bug</li>
            </ul>

            <h2>Using the website</h2>
            <ul>
              <li>
                How to use Profile Settings to create a your NEWA Dashboard
              </li>
              <li>General navigation</li>
              <li>Reporting an error or bug</li>
            </ul>

            <h2>Ideas and suggestions</h2>
            <ul>
              <li>Suggest a tool or resource</li>
              <li>Submit new ideas for future development</li>
            </ul>

            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>Page updated April 2020 by D. Olmstead and J. Carroll</p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
            <h2>Have a question?</h2>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Email the NEWA Help Desk
              </a>
            </p>
            <p>
              <a href="mailto:support@newa.zendesk.com" className="flex">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 mr-1 mail"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                </svg>
                support@newa.zendesk.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HelpPage
